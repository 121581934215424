@import "styles/mixins";

.login {
  width:100%;
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;
  @include mobile() {
    max-width: unset;
    height: 100%;
    margin: 0 auto;
    border-radius: 0px;
    padding:2rem 1rem 4rem 1rem;
  }

  .social {
    display: flex;
    justify-content: center;
  }

  h2 {
    color: var(--clr-neutral-800);
    font-size: 1.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    div {
      display: flex;
      flex-direction: column;
    }
  }

  .forgotPassword {
    text-align: right;
    color: var(--clr-primary-300);
    font-size: 0.875rem;
    text-decoration: none;
    padding-top: 5px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: var(--clr-accent-300);
    }
  }

  .error-message {
    color: #ff4d4d;
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
}

.passwordStrength {
  padding:5px 0 0 10px;
  text-align: left;
  font-size:0.75rem;
  font-weight:600;
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--clr-neutral-900);
    li {
      icon {
        display:flex;
      }
      font-weight:400;
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
}

.valid {
  color: green;
}

.invalid {
  color: red;
}

.orDivider {
  display: flex;
  align-items: center;
  text-align: center;
  color:var(--clr-neutral-800);

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid var(--clr-neutral-250);
  }

  &:before {
    margin-right: 0.75em;
  }

  &:after {
    margin-left: 0.75em;
  }
}